.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.loading-icon{
  position: absolute;
left: 0;
top: 0;
z-index: 9999;
height: 100%;
width: 100%;
background-color:
#e7e7e7;
opacity: .5;
padding-top: 15%;
padding-left: 49%;
}

.contentwrap {
  position: relative;
  z-index: 9;
  width: 100%;
  float: left;
}

#global-notification{
	top: -5px !important;
	bottom:0px !important;
}



a,
button,
input{
  outline: 0 !important;
  box-shadow: none !important;
}
